/* header2.css */
.div-header2 {
    align-items: center;
    background-color: var(--www-aos-fit-black, #000);
    display: flex;
    flex-direction: column;
    padding: 0 0px;
    width: 100%;
    z-index: 1;
}

.div-header2-2 {
    display: flex;
    align-items: center;
    margin: 0px 20px;
    gap: 650px;
}

.img-header2 {
    aspect-ratio: 2.59;
    object-fit: contain;
    object-position: center;
    width: 70px;
    max-width: 100%;
    overflow: hidden;
}

.div-header2-3 {
    flex-direction: column;
    /* Stack buttons vertically */
    align-items: center;
    /* Center buttons horizontally */
    margin: 20px 0;
}

.div-header2-4,
.div-header2-5 {
    color: var(--www-aos-fit-nero, #fff);
    /* text-align: center; */
    font-weight: 700;
}

@media (max-width: 991px) {
    /* .img-header2 {
        margin-top: 15px;
    } */

    .div-header2-2 {
        width: 90%;
        justify-content: space-between;
        gap: 0;
        margin: 0px 20px 0px 20px;
        /* flex-direction: column; */
        /* Remove gap between image and buttons */
    }

    .div-header2-3 {
        flex-direction: row;
        margin: 20px 0 20px 0;
        /* Arrange buttons horizontally */
    }
}