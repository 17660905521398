.div-87 {
    position: relative;
    display: flex;
    background-image: url('https://mediafusion.spacejat.com/api/fm/v1_download?file=YmFja2dyb3VuZGFvczpTVEw2OjgwNDczOmltYWdlL2pwZWc=.jpg&transformer=mobile_image');
    background-size: cover;
    /* Ensures the image covers the entire container */
    background-position: center;
    /* Centers the image within the container */
    max-width: 100%;
    flex-direction: column;
}

.div-88 {
    justify-content: center;
    color: #00e;
    text-align: center;
    align-self: stretch;
    white-space: nowrap;
    font: 500 31px/133% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-88 {
        max-width: 100%;
        white-space: initial;
    }
}

.div-89 {
    align-self: center;
    margin-top: 24px;
    width: 729px;
    max-width: 100%;
}

.div-90 {
    gap: 50px;
    display: flex;
}

@media (max-width: 991px) {
    .div-90 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

.column-10 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 47%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    .column-10 {
        width: 100%;
    }
}

.img-15 {
    aspect-ratio: 1.33;
    object-fit: contain;
    object-position: center;
    width: 100%;
    overflow: hidden;
}

@media (max-width: 991px) {
    .img-15 {
        margin-top: 40px;
    }
}

.column-11 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 53%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    .column-11 {
        width: 100%;
    }
}

.img-16 {
    aspect-ratio: 1.5;
    object-fit: contain;
    object-position: center;
    width: 100%;
    overflow: hidden;
}

@media (max-width: 991px) {
    .img-16 {
        margin-top: 40px;
    }
}

.div-91 {
    justify-content: center;
    color: #2f2f2f;
    width: 60%;
    margin: 34px 0px;
    text-align: center;
    align-self: center;
    margin-top: 34px;
    font: 700 20px/135% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-91 {
        max-width: 100%;
        white-space: initial;
    }
}