body.active-modal {
    overflow-y: hidden;
}

select {
    border-right: 16px solid transparent
}

.button {
    cursor: pointer;
    justify-content: center;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    align-items: center;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
    background-color: #00e;
    align-self: center;
    margin-top: 6px;
    width: 360px;
    max-width: 100%;
    padding: 10px 0px;
    font: 700 17px/154% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

body.active-modal {
    overflow: hidden;
}

.modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    overflow-y: auto;
}

.overlay {
    background: rgba(49, 49, 49, 0.8);
}

.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #fff;
    padding: 14px 28px;
    border-radius: 12px;
    max-width: 650px;
    min-width: 300px;
    width: 100%;
    box-sizing: border-box;
    max-height: 80vh;
    overflow-y: auto;
}

@media (max-width: 350px) {

    .modal,
    .overlay {
        position: absolute;
    }

}



.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

body {
    font-family: Arial, sans-serif;
}

select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    height: 45px;
    width: 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
    background-position: 100%;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 0;
}



/* Modal Container Styles */
.modalContainer {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: #FFF;
    padding: 20px;
    border: 3px solid #0074C7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 70%;
}


@media (max-width: 991px) {
    .modal-content {
        top: 52%;
        max-width: 90%;
    }

}



/* Logo Container Styles */
.logoContainer {
    text-align: center;
    /* margin-bottom: 20px; */
}

/* Form Input Styles */
.formInputs {
    margin-bottom: 20px;
}

.inputField {
    width: 35%;
    padding: 12px 18px;
    margin: 20px 30px 0px 0px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.inputFieldLeft {
    width: 35%;
    padding: 12px 18px;
    margin: 20px 0px 0px 0px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.inputRow {
    display: flex;
    justify-content: space-between;
}


@media (max-width: 991px) {
    .inputRow {
        display: block;
    }
}



.inputField-2 {
    width: 25%;
    padding: 12px 18px;
    margin: 20px 35px 20px 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.inputField-4 {
    width: 25%;
    padding: 12px 18px;
    margin: 20px 34px 20px 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.inputField-3 {
    flex: 1;
    width: 20%;
    padding: 12px 10px;
    margin: 20px 35px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

@media (max-width: 991px) {
    .inputField-2 {
        width: 100%;
        margin: 10px 0px;
    }

    .inputField {
        width: 90%;
        margin: 15px 0px;
    }

    .inputField-3 {
        width: 95%;
        margin: 10px 0px;
    }

    .inputField-4 {
        width: 100%;
        margin: 10px 0px;
    }

    .inputFieldLeft {
        width: 90%;
        margin: 10px 0px;
    }
}


/* Submit Button Styles */
.submitButton {
    text-align: center;
}

.submitBtn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #F31400;
    color: #FFF;
    text-decoration: none;
    border-radius: 3px;
    font-weight: 600;
    font-size: 16px;
}

/* Close Modal Button Styles */
.closeModalBtn {
    position: absolute;
    top: -2px;
    right: -6px;
    cursor: pointer;
}

/* Show Modal Animation */
.modalContainer.show {
    display: flex;
    animation: fadeIn 0.5s;
}


.formRow {
    line-height: normal;
    width: 100%;
    margin-top: 24px;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}