@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

@font-face {
  font-family: "Font Awesome 5 Free";
  src: url("/public/Font Awesome 5 Free-Solid-900.OTF");
  font-weight: 400;
  
}
@font-face {
  font-family: 'Futura LT Condensed';
  src: url('/public/FuturaLT.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply leading-[normal] m-0;
}

*,
::before,
::after {
  border-width: 0;
}