/* Add necessary styles for responsiveness */
#testimonials {
    padding: 2rem 0;
}

.testimonial-list {
    display: flex;
    overflow: hidden;
    justify-content: center;
    flex-wrap: nowrap;

    /* Ensure items don't wrap */
    list-style: none;
    /* Remove the default dots */
    padding: 0;
    /* Remove padding */
}

.testimonial-list li {
    flex: 1 0 auto;
    max-width: 100%;
}

@media (min-width: 640px) {
    /* .testimonial-list {
        justify-content: space-between;
    } */

    /* .testimonial-list li {
        max-width: 30%;
    } */
}

button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

button:focus {
    outline: none;
}

button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

button.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button.nav-button.left {
    left: -1rem;
}

button.nav-button.right {
    right: -1rem;
}

.testimonial-card {
    border: 2px solid rgb(195 143 132);
    /* Indigo border */
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 320px;
    color: #1c1c1e;
    /* Zinc color */
    padding: 1rem;
    /* Adjust padding as needed */
    margin: 0.5rem;
    /* Adjust margin as needed */
}

@media (max-width: 991px) {
    .testimonial-card {
        max-width: 250px;
    }
}

.testimonial-card img {
    width: 65px;
    height: 65px;
    margin: 0 auto;
    /* Center the image */
}

.testimonial-card p {
    margin-top: 1.5rem;
    /* Adjust as needed */
    width: 100%;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5;
}

.testimonial-card span {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
}

.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
}

.nav-button.left {
    left: 0;
}

.nav-button.right {
    right: 0;
}

.testimonial-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
}