.div-109 {
    justify-content: center;
    align-items: center;
    background-color: #474747;
    align-self: stretch;
    display: flex;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 991px) {
    .div-109 {
        max-width: 100%;
    }
}

.div-110 {
    display: flex;
    width: 580px;
    max-width: 100%;
    flex-direction: column;
}

.div-111 {
    justify-content: center;
    color: #fff;
    text-align: center;
    align-self: center;
    white-space: nowrap;
    font: 700 30px/140% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
    text-decoration: none;
    transition: border-color 0.3s;
    border-bottom: 1px solid transparent;
}

.div-111:hover {
    border-bottom: 3px solid rgb(255, 255, 255);
}

@media (max-width: 991px) {
    .div-111 {
        white-space: initial;
    }
}

.div-112 {
    justify-content: center;
    color: #fff;
    text-align: center;
    align-self: center;
    margin-top: 40px;
    white-space: nowrap;
    font: 500 22px Inter, sans-serif;
    text-decoration: none;
    transition: border-color 0.3s;
    border-bottom: 1px solid transparent;
}

.div-112:hover {
    border-bottom: 2px solid rgb(255, 255, 255);
}

@media (max-width: 991px) {
    .div-112 {
        margin-top: 40px;
        white-space: initial;
    }
}

.div-113 {
    justify-content: center;
    color: #fff;
    text-align: center;
    align-self: stretch;
    white-space: nowrap;
    font: 500 22px Inter, sans-serif;
    text-decoration: none;
    transition: border-color 0.3s;
    border-bottom: 1px solid transparent;
}

@media (max-width: 991px) {
    .div-113 {
        max-width: 100%;
        white-space: initial;
    }
}

.div-113:hover {
    border-bottom: 2px solid rgb(255, 255, 255);
}

.div-114 {
    justify-content: center;
    color: #fff;
    text-align: center;
    align-self: stretch;
    margin-top: 42px;
    white-space: nowrap;
    font: 400 15px/149% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-114 {
        max-width: 100%;
        margin-top: 40px;
        white-space: initial;
    }
}

.div-115 {
    justify-content: center;
    color: #fff;
    text-align: center;
    align-self: center;
    white-space: nowrap;
    font: 400 15px/150% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-115 {
        max-width: 100%;
        white-space: initial;
    }
}

.div-116 {
    justify-content: center;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    align-items: center;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
    background-color: #00e;
    align-self: center;
    margin-top: 12px;
    width: 366px;
    max-width: 100%;
    padding: 19px 0px;
    font: 700 19px/154% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-116 {
        white-space: initial;
    }
}